<template>
  <div class="wrapper flex flex-col gap-10" v-lazy-container="{ selector: 'img' }" ref="screen">
    <span class="close" @click="$emit('close-viewer')">&times;</span>
    <img :data-src="require('@/assets/images/rewards/' + reward.imgPath)" class="img-content" :alt="reward.description" :title="reward.displayName">
    <span class="text-zoom text-2xl"> {{reward.description}} </span>
  </div>
</template>
<script>
import party from "party-js";

export default {
  name: "FullScreenReward",
  props: {
    reward: {
      required: true,
      type: Object
    }
  },
  mounted() {
    setTimeout(() => {
      party.confetti(this.$refs.screen, {
        count: 100,
        size: party.variation.range(1, 1),
        gravity: party.variation.range(1, 2),
        shapes: ["square", "star"],
      });
    }, 700);
  },
  emits: ['close-viewer']
};
</script>


<style scoped>
  .wrapper {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-content {
    max-width: 50%;
    max-height: 50%;
  }

  .img-content {
    animation-name: zoomAndRotate;
    animation-duration: 1s;
  }

  .text-zoom {
    transform: scale(0);
    animation-name: zoom;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-delay: 0.1s;
  }

  .wrapper .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .wrapper .close:hover,
  .wrapper .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  @keyframes zoomAndRotate {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1) rotateY(1turn)

    }
  }

  @keyframes zoom {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1)

    }
  }
</style>
