export const chartOptions = {
  chart: {
    id: 'vuechart-example',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: true
      }
    }
  },
  legend: {
    show: false
  },
  tooltip: {
    enabled: true,
    theme: 'dark',
    x: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    tooltip: {
      enabled: false
    }
  }
};
