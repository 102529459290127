<template>
  <div class="h-auto lg:h-full bg-white rounded-xl flex flex-col justify-between p-5">
    <span class="text-space-dark-blue font-raleway-extra-bold text-xl">
      {{$t('dashboard.game_per_month.evolution')}}
    </span>
    <apexchart ref="gamePerMonth" width="100%" height="200" type="area" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import DashboardService from '../../../services/dashboardService';
import {chartOptions} from "@/constants/dashboard/chart-options";

export default {
  name: "GamePerMonth",
  async mounted() {
    this.series[0] = {...this.series[0], data: await this.getGamesPerMonth(false)};
    this.series[1] = {...this.series[1], data: await this.getGamesPerMonth(true)};
    this.$refs.gamePerMonth.updateSeries(this.series);
  },
  methods: {
    getGamesPerMonth(wonGame) {
      return DashboardService.getGamesPerMonth(wonGame);
    }
  },
  data() {
    return {
      options: chartOptions,
      series: [
        {
          name: this.$t('dashboard.game_per_month.total_game'),
          data: []
        },
        {
          name: this.$t('dashboard.game_per_month.win_game'),
          data: []
        }]
    };
  },
};
</script>
