<template>
  <div class="h-full">
    <div class="flex flex-col md:flex-row gap-5" v-if="data.length > 0">
      <GamePerLanguageCard v-for="(game, index) in data" :key="index" :language="game.language" :games="game.count" />
    </div>
    <div class="flex flex-row gap-5" v-else>
      <GamePerLanguageCard v-for="(language) in AVAILABLE_LANGUAGES()" :key="language" :language="language" :games="0" />
    </div>
  </div>
</template>

<script>
import GamePerLanguageCard from "@/components/Dashboard/GamerPerLanguages/GamePerLanguageCard.vue";
import DashboardService from "@/services/dashboardService";
import {AVAILABLE_LANGUAGES} from "@/constants/languages/LanguagesConstants";

export default {
  name: "GamePerLanguages",
  components: {GamePerLanguageCard},
  data() {
    return {
      data: [],
      languagesAvailable: AVAILABLE_LANGUAGES
    };
  },
  async mounted() {
    this.data = await this.constructData();
  },
  methods: {
    AVAILABLE_LANGUAGES() {
      return AVAILABLE_LANGUAGES;
    },
    async constructData() {
      const response = await DashboardService.getCountGamesPerLanguages();

      return this.languagesAvailable.map((language) => {
        const count = response.find((value) => value.language === language);
        return {
          language: language,
          count: count ? parseInt(count.games) : 0
        };
      });
    }
  }
};
</script>
