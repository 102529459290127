<template>
  <div v-if="rewardsPlayer" class="h-full bg-white rounded-xl p-5 flex flex-col gap-3 overflow-hidden" v-lazy-container="{ selector: 'img' }">
    <span class="text-space-dark-blue font-raleway-extra-bold text-xl">
      {{$t('dashboard.rewards.title')}}
    </span>

    <Reward :rewards="rewards"/>
  </div>
</template>

<script>
import DashboardService from "../../../services/dashboardService";
import Reward from "./Reward.vue";
import {REWARDS} from "../../../constants/dashboard/rewards";

export default {
  name: "Rewards",
  components: {Reward},
  data() {
    return {
      rewardsPlayer: [],
      rewards: REWARDS
    };
  },
  async mounted() {
    this.rewardsPlayer = await this.getRewards();

    this.rewards = this.rewards.map((reward) => {
      const rewardP = this.rewardsPlayer.find((value) => value.name === reward.name);
      if (rewardP) {
        reward.owned = true;
      }
      return reward;
    }).sort((a, b) => (a.owned === b.owned) ? 0 : a.owned ? -1 : 1);
  },
  methods: {
    getRewards() {
      return DashboardService.getRewards();
    }
  }
};
</script>
