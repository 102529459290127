<template>
  <div class="h-full bg-white rounded-xl p-5 text-space-dark-blue flex flex-col gap-3">
    <div>
      <span class="font-raleway text-lg">{{$t('dashboard.game_history.title')}}</span> <br>
      <span class="font-raleway-extra-bold text-xl">{{$t('dashboard.game_history.subtitle')}}</span>
      <p class="text-sm">{{$t('dashboard.game_history.description')}}</p>
    </div>
    <div class="max-h-full overflow-scroll table-gh-container">
      <Table
        :headers="headers"
        :pagination-values="paginationValues"
        :is-loading="loading"
        :data="games"
      >
        <template #body>
          <GameHistoryTableBody :games="games" />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from "../../Table/Table.vue";
import DashboardService from "../../../services/dashboardService";
import {GAME_HISTORY_HEADER} from "@/constants/dashboard/game-history";
import GameHistoryTableBody from "./GameHistoryTableBody.vue";

export default {
  name: "GameHistory",
  components: {GameHistoryTableBody, Table},
  data() {
    return {
      headers: GAME_HISTORY_HEADER,
      loading: true,
      games: [],
      paginationValues: {
        page: 1,
        pageSize: 20
      },
    };
  },
  beforeDestroy() {
    const tableContainer = this.$el.querySelector('.table-gh-container');
    tableContainer.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.getGameHistory(this.paginationValues.page);
    this.$nextTick(() => {
      const tableContainer = this.$el.querySelector('.table-gh-container');
      tableContainer.addEventListener('scroll', this.handleScroll);
    });
  },
  methods: {
    getGameHistory(params) {
      DashboardService.getGameHistory({
        page: params || this.paginationValues.page,
        pageSize: this.paginationValues.pageSize
      })
        .then((response) => {
          this.games = [...this.games, ...response.results];
          this.paginationValues = {
            ...this.paginationValues,
            total: response.total,
            page: response.page,
            totalPages: response.totalPages,
            totalItemsInPage: response.totalItemsInPage
          };
        })
        .finally(() => this.loading = false);
    },

    handleScroll() {
      const tableContainer = this.$el.querySelector('.table-gh-container');
      if (tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight) {
        if (this.paginationValues.page < this.paginationValues.totalPages) {
          this.getGameHistory(this.paginationValues.page + 1);
        }
      }
    }
  }
};
</script>
