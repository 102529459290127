<template>
  <HomeLayout>
    <template #content>
      <div class="flex flex-col lg:h-[80vh] overflow-y-scroll md:grid grid-template lg:grid-cols-2 gap-5">
        <div class="col-start-1 row-start-1">
          <GamePerLanguages />
        </div>
        <div class="lg:col-start-1 lg:row-start-2">
          <GamePerMonth />
        </div>
        <div class="lg:col-start-1 lg:row-start-3">
          <Rewards />
        </div>
        <div class="lg:row-start-1 lg:col-start-2 lg:row-span-3">
          <GameHistory />
        </div>
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layouts/HomeLayout.vue";
import GamePerMonth from "@/components/Dashboard/GamePerMonth/GamePerMonth.vue";
import GamePerLanguages from "@/components/Dashboard/GamerPerLanguages/GamePerLanguages.vue";
import GameHistory from "../../components/Dashboard/GameHistory/GameHistory.vue";
import Rewards from "../../components/Dashboard/Rewards/Rewards.vue";

export default {
  name: "Dashboard",
  components: {Rewards, GameHistory, GamePerLanguages, GamePerMonth, HomeLayout}
};
</script>

<style scoped>
.grid-template {
  grid-template-rows: auto 1fr 1fr;
}

.grid-template::-webkit-scrollbar {
  display: none;
}
</style>
