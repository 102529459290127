import i18n from "../../i18n/i18n";

export const GAME_HISTORY_HEADER_STATE = {
  name: i18n.t('dashboard.game_history.state'),
  value: 'state'
};

export const GAME_HISTORY_HEADER_MODE = {
  name: i18n.t('dashboard.game_history.type'),
  value: 'mode'
};

export const GAME_HISTORY_HEADER_PLAYERS = {
  name: i18n.t('dashboard.game_history.players'),
  value: 'players'
};

export const GAME_HISTORY_HEADER_DATE = {
  name: i18n.t('dashboard.game_history.date'),
  value: 'date'
};

export const GAME_HISTORY_HEADER = [
  GAME_HISTORY_HEADER_STATE,
  GAME_HISTORY_HEADER_MODE,
  GAME_HISTORY_HEADER_PLAYERS,
  GAME_HISTORY_HEADER_DATE
];
