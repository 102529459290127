<template>
  <tr class="font-raleway">
    <td v-for="header in headers" :key="header.value">
      <span v-if="header.value === GAME_HISTORY_HEADER_STATE.value" class="font-raleway-extra-bold">
        {{game.state ? $t('dashboard.game_history.win') : $t('dashboard.game_history.loose')}}
      </span>

      <span v-else-if="header.value === GAME_HISTORY_HEADER_MODE.value" class="font-raleway">
        {{getModeTranslated(game.gameMode)}}
      </span>

      <div v-else-if="header.value === GAME_HISTORY_HEADER_PLAYERS.value" class="flex flex-row">
        <UserAvatar v-for="(player, index) in game.players" :key="index"
                    :user="player"
                    :class="'w-6 h-6'"
        />
      </div>

      <span v-else-if="header.value === GAME_HISTORY_HEADER_DATE.value">
        {{formatDate(game.date)}}
      </span>

      <span v-else></span>
    </td>
  </tr>
</template>

<script>
import {findParentComponentByName} from "../../../utils/FindParentComponent";
import {
  GAME_HISTORY_HEADER_DATE,
  GAME_HISTORY_HEADER_MODE,
  GAME_HISTORY_HEADER_PLAYERS,
  GAME_HISTORY_HEADER_STATE
} from "../../../constants/dashboard/game-history";
import {GAME_TYPES} from "../../../constants/games/GameTypes";
import DateUtils from "../../../utils/DateUtils";
import UserAvatar from "../../avatar/UserAvatar.vue";

export default {
  name: "GameHistoryRow",
  components: {UserAvatar},
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  computed: {
    GAME_HISTORY_HEADER_DATE() {
      return GAME_HISTORY_HEADER_DATE;
    },
    GAME_HISTORY_HEADER_PLAYERS() {
      return GAME_HISTORY_HEADER_PLAYERS;
    },
    GAME_HISTORY_HEADER_MODE() {
      return GAME_HISTORY_HEADER_MODE;
    },
    GAME_HISTORY_HEADER_STATE() {
      return GAME_HISTORY_HEADER_STATE;
    },
    GAME_TYPE_TRANSLATED(value) {
      return GAME_TYPES.find((type) => type.type === value).name;
    },
    headers() {
      return findParentComponentByName(this, 'Table')?.$props?.headers;
    }
  },
  methods: {
    getModeTranslated(value) {
      return GAME_TYPES.find((type) => type.type === value).name;
    },
    formatDate(value) {
      return DateUtils.formatDate(value);
    }
  }
};
</script>
