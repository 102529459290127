<template>
  <div class="flex flex-row flex-nowrap gap-10 overflow-x-scroll overflow-y-hidden webkit-scroll pb-2">
    <div v-for="reward in rewards" :key="reward.name" class="rewards flex flex-col items-center gap-3" @click="openViewer(reward)">
      <img :data-src="require('@/assets/images/rewards/' + reward.imgPath)" :alt="reward.description"
           :class="locked(reward) + ' reward-img'">

      <span class="text-space-dark-blue font-raleway-extra-bold text-lg text-center">
        {{reward.displayName}}
      </span>
    </div>
    <FullScreenReward v-if="showViewer" :reward="reward" @close-viewer="closeViewer()"/>
  </div>
</template>

<script>
import FullScreenReward from "./FullScreenReward.vue";

export default {
  name: "Reward",
  components: {FullScreenReward},
  data() {
    return {
      showViewer: false,
      reward: {}
    };
  },
  props: {
    rewards: {
      required: true,
      type: Array
    }
  },
  methods: {
    locked(reward) {
      return reward.owned ? '' : 'locked prevent';
    },
    openViewer(reward) {
      if (!reward.owned) { return }
      this.reward = reward;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
      this.reward = {};
    }
  }
};
</script>

<style scoped>
.rewards {
  min-width: 100px;
}

.reward-img {
  height: 110px;
}

.webkit-scroll::-webkit-scrollbar:horizontal{
  height: 9px;
}

.locked {
  filter: grayscale(100%);
  cursor: not-allowed;
}
</style>
