import {API} from "@/utils/api";

class DashboardService {
  getGamesPerMonth(wonGame) {
    const startDate = '2023-01-01';
    let endDate = new Date();
    // MERCI LE JS..
    endDate = `${endDate.getFullYear()}/${(endDate.getMonth() + 2).toString().padStart(2, '0')}/${endDate.getDate().toString().padStart(2, '0')}`;
    return API.get(`user/game-per-month?wonGame=${wonGame}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => response.data)
      .catch(() => this.$toast.error(this.$t('dashboard.error').toString()));
  }

  getCountGamesPerLanguages() {
    return API.get(`user/game-per-language`)
      .then((response) => response.data.results)
      .catch(() => this.$toast.error(this.$t('dashboard.error').toString()));
  }

  getGameHistory(param) {
    return API.get(`user/game-history`, {
      params: param
    }).then((response) => response.data)
      .catch(() => this.$toast.error(this.$t('dashboard.error').toString()));
  }

  getRewards() {
    return API.get('user/rewards')
      .then((response) => response.data.data)
      .catch(() => this.$toast.error(this.$t('dashboard.error').toString()));
  }
}

export default new DashboardService();
