<template>
  <tbody>
    <GameHistoryRow
      v-for="(game, index) in games"
      :key="index"
      :game="game"
    />
  </tbody>
</template>

<script>
import GameHistoryRow from "./GameHistoryRow.vue";

export default {
  name: "GameHistoryTableBody",
  components: {GameHistoryRow},
  props: {
    games: {
      type: Array,
      required: true
    }
  }
};
</script>
