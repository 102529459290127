<template>
  <div class="bg-white rounded-xl text-space-dark-blue p-5 flex flex-col gap-2 items-center">
    <div class="font-raleway text-center text-sm">
      {{ $t('dashboard.game_per_language.card_title') }} <br>
      <span class="font-raleway-extra-bold text-lg">
        {{ $t('dashboard.game_per_language.card_subtitle') }}
      </span>
    </div>
    <div>
      <font-awesome-icon
        v-for="icon in getExercisesLanguagesIcons([{language: language}])"
        :key="icon"
        :icon="icon"
        class="h-8 w-8"
        :title="language"
      />
    </div>
    <div class="geminis text-4xl">
      {{startNumber}}
    </div>
  </div>
</template>

<script>
import {getExercisesLanguagesIcons} from "@/constants/languages/LanguagesConstants";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "GamePerLanguageCard",
  components: {FontAwesomeIcon},
  props: {
    games: {
      required: true,
      type: Number
    },
    language: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      startNumber: 0
    };
  },
  mounted() {
    this.incrementNumber(this.startNumber);
  },
  methods: {
    getExercisesLanguagesIcons,
    incrementNumber(number) {
      if (number === this.games) {
        return;
      }
      setTimeout(() => {
        this.startNumber += 1;
        this.incrementNumber(this.startNumber);
      }, 10);
    }
  }
};
</script>
